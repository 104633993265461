import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
export const baseURL = "https://legalconsultationapp-98247-ruby.b98247.stage.eastus.az.svc.builder.ai"

import RecordRTC from "recordrtc";
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  voiceMemoslist: VoiceMemo[];
  voiceMemo: VoiceMemo;
  showDialog: boolean;
  isBlocked: boolean;
  seconds: number
  updSecond: number
  minute: number
  hour: number
  showErrorDialog: boolean,
  status: string;
  dialogMode: string;
  deleteItemId: string;
  saveVoiceNote: boolean,
  page: number,
  errorMessage: string;
  successMessage: string;
  totalPages: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface VoiceMemo {
  id: string,
  type: string,
  attributes: {
    filename: string,
    record_time: string,
    created_at: string,
    updated_at: string,
    voice_record: string
  }
}
interface Apidata {
  contentType: string,
  method: string,
  endPoint: string,
  body: Object,
  type: string,
}


interface ListResponse {
  total_page?: number,
  serializer?: {
    data: VoiceMemo[]
  },
  errors?: {
    message: string
  },
  message?: string,
  data?: VoiceMemo
}

// Customizable Area End

export default class VoiceMemosController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetVoiceMemos: string = "";
  apiCallIdCreateVoiceMemos: string = "";
  apiCallIdDeleteVoiceMemo: string = "";
  recorder: RecordRTC | undefined;
  interval: number | undefined;
  // Customizable Area End


  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      voiceMemoslist: [],
      showDialog: false,
      showErrorDialog: false,
      voiceMemo: {
        id: '',
        type: '',
        attributes: {
          filename: '',
          record_time: '',
          created_at: '',
          updated_at: '',
          voice_record: ''
        }
      },
      isBlocked: false,
      seconds: 0,
      updSecond: 0,
      minute: 0,
      hour: 0,
      status: 'play',
      dialogMode: "",
      deleteItemId: "",
      saveVoiceNote: true,
      page: 0,
      errorMessage: '',
      successMessage: '',
      totalPages: 0

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.apiCallIdGetVoiceMemos:
            this.handleVoiceMemosResponse(responseJson);
            break;
          case this.apiCallIdCreateVoiceMemos:
            this.handleCreateVoiceMemosResponse(responseJson);
            break;
          case this.apiCallIdDeleteVoiceMemo:
            this.handleDeleteVoiceMemoResponse(responseJson);
            break;
        }
      }

      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setState({ txtSavedValue: value });

    }
    // Customizable Area End
  }



  // Customizable Area Start

  async componentDidMount() {
    this.getVoiceMemos(this.state.page);
    this.requestRecorder();
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;



  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgInfo = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgInfo.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgInfo);
  }

  // web events

  getVoiceMemos = async (page: number) => {
    this.apiCallIdGetVoiceMemos = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.voiceMemoApiEndPoint}?page=${page}&per_page=5`,
      body: '',
      type: ""
    });
  }

  createVoiceMemo = async (fileinfo: File, time: string, filename: string) => {
    let formData = new FormData();
    formData.append('voice_record', fileinfo);
    formData.append('filename', filename);
    formData.append('record_time', time);

    this.apiCallIdCreateVoiceMemos = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleApiMethod,
      endPoint: configJSON.voiceMemoApiEndPoint,
      body: formData,
      type: "formData"
    });
  }



  handleDialogOpen = (item: VoiceMemo) => {
    this.setState({ voiceMemo: item, showDialog: true });
  }
  handleDialogClose = () => {
    this.setState({ showDialog: false });
  }
  handleMessageDialogClose = () => {
    this.setState({ showErrorDialog: false });
  }

  requestRecorder = async () => {
    try {
      this.setState({ isBlocked: false });
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      this.recorder = new RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/webm"
      });
    } catch (error: unknown) {
      this.setState({ isBlocked: true, showErrorDialog: true, dialogMode: 'warning' });
    }
  }

  startRecording = () => {
    if (!this.state.isBlocked) {
      this.setState({ updSecond: 0, minute: 0, hour: 0, seconds: 0, status: "pause" });
      this.interval = window.setInterval(this.upTimer, 1000);
      if (this.recorder) {
        this.recorder.startRecording();
      }
    }
    else {
      this.setState({ isBlocked: true, showErrorDialog: true });
    }
  }
  pauseRecording = () => {
    this.setState({ status: "resume" });
    if (this.recorder) {
      this.recorder.pauseRecording();
    }
    clearInterval(this.interval);
  }

  resumeRecording = () => {
    this.setState({ status: "pause" });
    if (this.recorder) {
      this.recorder.resumeRecording();
    }
    this.interval = window.setInterval(this.upTimer, 1000);
  }

  openSaveConfirmDialog = () => {
    this.pauseRecording();
    this.setState({ dialogMode: "saveconfirm", showErrorDialog: true });
  }
  openDeleteConfirmDialog = (voiceMemoId: string) => {
    this.setState({ dialogMode: "deleteconfirm", showErrorDialog: true, deleteItemId: voiceMemoId });
  }

  stopRecording = () => {
    this.setState({ showErrorDialog: false });
    if (this.recorder) {
      this.recorder.stopRecording(this.stopRecordingCallBack);
    }
  }

  stopRecordingCallBack = async () => {
    clearInterval(this.interval);
    if (this.recorder) {
      let blobData: Blob = this.recorder.getBlob();
      let milliseconds = new Date().getTime();
      let timestamp = (milliseconds.toString()).substring(9, 13);
      let randomFileName = "file" + timestamp;
      const voiceMemoFile = new File([blobData], `${randomFileName}.mp3`, {
        type: "audio/mpeg",
        lastModified: Date.now()
      });

      const objectUrl = URL.createObjectURL(voiceMemoFile) //file[0
      let audioData = new Audio(objectUrl)
      audioData.onloadedmetadata = () => {
        let seconds = Math.round(audioData.duration);
        let hour = Math.floor(seconds / 3600);
        let minute = Math.floor((seconds - hour * 3600) / 60);
        let updSecond = seconds - (hour * 3600 + minute * 60);
        let duration = `${'00'}:${(minute > 9 ? minute : '0' + minute)}:${(updSecond > 9 ? updSecond : '0' + updSecond)}`
        if (this.state.saveVoiceNote) {
          this.createVoiceMemo(voiceMemoFile, duration, randomFileName);
        }
      }
    }
  }

  deleteVoiceNote = async (voiceMemoId: string) => {
    this.apiCallIdDeleteVoiceMemo = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethodType,
      endPoint: configJSON.voiceMemoApiEndPoint + `/${voiceMemoId}`,
      body: "",
      type: ""
    });
  }


  upTimer = () => {
    let second = this.state.seconds;
    this.setState({ seconds: ++second });
    let hour = Math.floor(this.state.seconds / 3600);
    let minute = Math.floor((this.state.seconds - hour * 3600) / 60);
    let updSecond = this.state.seconds - (hour * 3600 + minute * 60);
    this.setState({ updSecond: updSecond, minute: minute, hour: hour });
    if (minute >= 1) {
      this.pauseRecording();
      this.setState({ showErrorDialog: true, dialogMode: "maxlimit" });
    }
  }
  handleSaveYesAction = () => {
    if (this.state.dialogMode == "saveconfirm" || this.state.dialogMode == "maxlimit") {
      this.setState({ saveVoiceNote: true })
      this.stopRecording();
    }
    if (this.state.dialogMode == "deleteconfirm") {
      this.setState({ showErrorDialog: false })
      this.deleteVoiceNote(this.state.deleteItemId);
    }
  }
  handleDiscardNoAction = () => {
    if (this.state.dialogMode == "saveconfirm" || this.state.dialogMode == "maxlimit") {
      this.setState({ saveVoiceNote: false, status: "play", updSecond: 0, minute: 0, hour: 0, seconds: 0 })
      this.stopRecording();
    }
    if (this.state.dialogMode == "deleteconfirm") {
      this.setState({ showErrorDialog: false, deleteItemId: "" })
    }
  }
  loadFunc = () => {
    if (this.state.page < this.state.totalPages) {
      this.getVoiceMemos(this.state.page);
    }
  }

  apiCall = async (data: Apidata) => {
    const { method, endPoint, body, type } = data;
    const headers = {
      "token": configJSON.token 
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (body) {
      type != 'formData' ?
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )

        : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleVoiceMemosResponse = (responseJson: ListResponse) => {
    if (responseJson && !responseJson.errors) {
      this.voiceMemosSuccessCallback(responseJson);
    }
    if (responseJson && responseJson.errors) {
      this.voiceMemosErrorCallback(responseJson);
    }
  };

  handleCreateVoiceMemosResponse = (responseJson: ListResponse) => {
    if (responseJson && !responseJson.errors) {
      this.voiceMemoCreateSuccessCallback(responseJson);
    }
    if (responseJson && responseJson.errors) {
      this.voiceMemoCreateErrorCallback(responseJson);
    }
  };

  handleDeleteVoiceMemoResponse = (responseJson: ListResponse) => {
    if (responseJson && !responseJson.errors) {
      this.voiceMemoDeleteSuccessCallback(responseJson);
    }
    if (responseJson && responseJson.errors) {
      this.voiceMemoDeleteErrorCallback(responseJson);
    }
  };

  voiceMemosSuccessCallback = (responseJson: ListResponse) => {
    if (responseJson.total_page && responseJson.serializer && responseJson.serializer.data && responseJson.serializer.data.length > 0) {
      if (this.state.page == 0) {
        this.setState({ voiceMemoslist: responseJson.serializer.data, page: this.state.page + 1, totalPages: responseJson.total_page });
      }
      else {
        this.setState({ voiceMemoslist: this.state.voiceMemoslist.concat(responseJson.serializer.data), page: this.state.page + 1, totalPages: responseJson.total_page });
      }
    }
    else if (responseJson.message) {
      this.setState({ errorMessage: responseJson.message, voiceMemoslist: this.state.voiceMemoslist, page: this.state.page });
    }
    else {
      this.setState({ voiceMemoslist: [], page: this.state.page + 1 });
    }
  }

  voiceMemosErrorCallback = (responseJson: ListResponse) => {
    if (responseJson.errors) {
      alert(responseJson.errors.message);
      this.setState({ errorMessage: responseJson.errors.message });
    }
  };

  voiceMemoDeleteSuccessCallback = (responseJson: ListResponse) => {
    if (responseJson.message) {
      this.setState({ successMessage: responseJson.message, page: 0 });
      alert(responseJson.message);
      this.getVoiceMemos(0);
    }
  }

  voiceMemoDeleteErrorCallback = (responseJson: ListResponse) => {
    if (responseJson.errors) {
      this.setState({ errorMessage: responseJson.errors.message });
      alert(responseJson.errors.message);
    }
  }
  voiceMemoCreateSuccessCallback = (responseJson: ListResponse) => {
    if (responseJson && responseJson.data) {
      this.setState({ status: "play", updSecond: 0, minute: 0, hour: 0, seconds: 0, page: 0, successMessage: configJSON.createSuccessResp });
      this.getVoiceMemos(0);
    }
  }
  voiceMemoCreateErrorCallback = (responseJson: ListResponse) => {
    if (responseJson.errors) {
      alert(responseJson.errors.message);
      this.setState({ errorMessage: responseJson.errors.message });
    }
  }

  // Customizable Area End
}
